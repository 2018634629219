exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-our-team-js": () => import("./../../../src/pages/about-us/our-team.js" /* webpackChunkName: "component---src-pages-about-us-our-team-js" */),
  "component---src-pages-about-us-rates-js": () => import("./../../../src/pages/about-us/rates.js" /* webpackChunkName: "component---src-pages-about-us-rates-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-information-js": () => import("./../../../src/pages/patient-information.js" /* webpackChunkName: "component---src-pages-patient-information-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

